import nubrakesLogo from '../../public/img/nubrakes-logo.svg'
import Image from 'next/image'
import React, { useState, forwardRef, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import phoneIcon from '../../public/img/phone-icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import Link from 'next/link'
import AnimateHeight from 'react-animate-height'
import { ParsedUrlQuery } from 'querystring'
import classNames from 'classnames'
import { useQueryParam } from '../hooks/useQueryParam'
import { groupBy, prop } from 'ramda'
import { formatPhoneNumber } from './utils/string-utils'

type Props = {
  phoneNumber: string
  phoneNumberTxt: string
}

// TODO: Layout.tsx use the serviceCategory field on the Page Model to generate this data structure
export const services = [
  { linkTitle: 'Brake Pad Replacement', link: '/services/brake-pad-replacement', groupName: 'Brake Services' },
  {
    linkTitle: 'Brake Rotor Replacement',
    link: '/services/brake-rotor-replacement/',
    groupName: 'Brake Services',
  },
  { linkTitle: 'Brake Fluid Flush', link: '/services/brake-fluid-flush', groupName: 'Brake Services' },
  { linkTitle: 'Brake Shoe Replacement', link: '/services/brake-shoe-replacement/', groupName: 'Brake Services' },
  { linkTitle: 'Brake Drum Replacement', link: '/services/brake-drum-replacement/', groupName: 'Brake Services' },
  { linkTitle: 'Brake Hose Replacement', link: '/services/brake-hose-replacement/', groupName: 'Brake Services' },
  { linkTitle: 'Brake Caliper Replacement', link: '/services/brake-caliper-replacement/', groupName: 'Brake Services' },
  { linkTitle: 'Windshield Wiper Fluid Fill', link: '/services/windshield-wiper-fluid-fill/', groupName: 'Wipers' },
  { linkTitle: 'Windshield Wiper Replacement', link: '/services/windshield-wiper-replacement/', groupName: 'Wipers' },
  {
    linkTitle: 'Wheel Cylinder Replacement',
    link: '/services/wheel-cylinder-replacement/',
    groupName: 'Brake Services',
  },
  { linkTitle: 'Oil Change', link: '/services/oil-change/', groupName: 'General Maintenance' },
  { linkTitle: 'Tire Rotation', link: '/services/tire-rotation/', groupName: 'General Maintenance' },
  { linkTitle: 'Check Engine Light', link: '/services/check-engine-light', groupName: 'General Maintenance' },
  {
    linkTitle: 'General Maintenance Package',
    link: '/services/general-maintenance-package',
    groupName: 'General Maintenance',
  },
  // { linkTitle: 'Brake Light Bulb Replacement', link: '/services/brake-light-bulb-replacement/', groupName: 'Lights' },
  // { linkTitle: 'Turn Signal Bulb Replacement', link: '/services/turn-signal-bulb-replacement/', groupName: 'Lights' },
  // { linkTitle: 'Tail Light Bulb Replacement', link: '/services/tail-light-bulb-replacement/', groupName: 'Lights' },
  // { linkTitle: 'Headlight Bulb Replacement', link: '/services/headlight-bulb-replacement/', groupName: 'Lights' },
  { linkTitle: 'Car Battery Replacement', link: '/services/battery-replacement/', groupName: 'Car Batteries' },

  { linkTitle: 'Cabin Air Filter Replacement', link: '/services/cabin-air-filter-replacement/', groupName: 'Filters' },
  {
    linkTitle: 'Engine Air Filter Replacement',
    link: '/services/engine-air-filter-replacement/',
    groupName: 'Filters',
  },

  { linkTitle: 'ABS Speed Sensor Replacement', link: '/services/abs-speed-sensor-replacement/', groupName: 'Sensors' },
  {
    linkTitle: 'Wheel Speed Sensor Replacement',
    link: '/services/wheel-speed-sensor-replacement/',
    groupName: 'Sensors',
  },
  // {
  //   linkTitle: 'A/C Belt Replacement',
  //   link: '/services/ac-belt-replacement/',
  //   groupName: 'Belt Replacement',
  // },
  // {
  //   linkTitle: 'Power Steering Belt Replacement',
  //   link: '/services/power-steering-belt-replacement/',
  //   groupName: 'Belt Replacement',
  // },
  // {
  //   linkTitle: 'Drive Belt Replacement',
  //   link: '/services/drive-belt-replacement/',
  //   groupName: 'Belt Replacement',
  // },
]

const removeBuilderURLParam = (query) => {
  // we pass query to our nav links
  // builder sets the page property on query
  const { page, cities, ...cleanedQuery } = query
  // page removed from visible query
  page || cities ? 'cleaned from url' : 'no page' // appeasing eslint
  return cleanedQuery
}

function NavContents(props: {
  query: ParsedUrlQuery
  phoneNumber: string
  phoneNumberTxt: string
  setOpen?: any
  isOpen?: boolean
  isSubNavOpen?: boolean
  setSubNavOpen?: any
}) {
  const [dropDownEnabled, setDropDownEnabled] = useState(true) // Toggle to turn off the services links
  const { isSubNavOpen, setSubNavOpen, isOpen } = props
  React.useEffect(() => {
    setDropDownEnabled(true) // toggle off the services drop
  }, [])
  return (
    <>
      <div className="text-base lg:flex-grow">
        {dropDownEnabled && (
          <DropDownNavItem
            onClick={() => {
              setSubNavOpen(!isSubNavOpen)
            }}
            text={'AUTO SERVICES'}
            isSubNavOpen={isSubNavOpen}
            isOpen={isOpen}
            subPages={services}
            setSubNavOpen={setSubNavOpen}
          />
        )}
        <Link href={{ pathname: `/financing/`, query: props.query }} passHref={true} prefetch={false}>
          <NavItem text={'FINANCING'} />
        </Link>
        <NavItem href="https://www.classet.org/org/nubrakes" text={'BECOME A TECHNICIAN'} />

        <Link href={{ pathname: `/fleet-service/`, query: props.query }} passHref={true} prefetch={false}>
          <NavItem text={'FLEET SERVICE'} />
        </Link>
        <span className={'sm:hidden'}>
          <Link href={{ pathname: `/get-quote/`, query: props.query }} passHref={true} prefetch={false}>
            <NavItem text={'GET QUOTE'} />
          </Link>
        </span>
        <hr className={'mt-4 mb-2 sm:hidden'} />
      </div>
      <div className="flex items-center">
        <div className="text-right pr-2 xl:flex xl:flex-col hidden" style={{ flex: '0 0 auto' }}>
          <div className="font-bold text-black leading-none text-sm">NEED HELP?</div>
          <div className="leading-none text-xs text-gray-500">Call us now:</div>
        </div>
        <Image className="xl:flex hidden " src={phoneIcon} alt={'Phone Icon'} />
        <a
          className="lg:px-2 text-2xl font-bold hover:text-primary text-black ml-2 sm:ml-0"
          style={{ flex: '0 0 auto' }}
          href={`tel:${props.phoneNumber}`}
        >
          {formatPhoneNumber(props.phoneNumber)}
        </a>
      </div>
      <div className="hidden xl:flex lg:ml-3 flex">
        <Link href={{ pathname: `/get-quote/`, query: props.query }} passHref={true}>
          <a className={'flex flex-grow'}>
            <button className="flex flex-grow justify-center bg-lime hover:bg-green-600 text-white py-2 text-xs font-semibold px-5 rounded">
              GET A QUOTE
            </button>
          </a>
        </Link>
      </div>
    </>
  )
}

export const Navbar: React.FC<Props> = ({ phoneNumber = '8558005629', phoneNumberTxt = '(855) 800-5629' }) => {
  const [isOpen, setOpen] = useState(false)
  const [isSubNavOpen, setSubNavOpen] = useState(false)
  // const toggle = () => setOpen(!isOpen)
  const closeNav = () => setOpen(false)
  const openNav = () => setOpen(true)
  const height = isOpen ? 'auto' : 0
  const query = useQueryParam()
  const navItemContainerClasses = `w-full block flex-grow lg:flex lg:items-center lg:w-auto`

  return (
    <nav className="sticky top-0 flex items-center justify-between flex-wrap bg-white py-2 px-4 border-b navbar-wrapper">
      <div className="flex items-center text-white mr-6 cursor-pointer w-1/2 sm:w-1/3 md:w-1/4 lg:w-[210px]">
        <Link href={{ pathname: `/`, query: removeBuilderURLParam(query) }} passHref={true}>
          <a className="mt-2 px-0 md:px-2 max-w-[210px]">
            <Image alt="NuBrakes Logo" src={nubrakesLogo} priority={true} />
          </a>
        </Link>
      </div>
      <div className="flex items-center justify-between mt-1 mr-4 block lg:hidden hover:cursor-pointer">
        <a
          href={`tel:${phoneNumber}`}
          className="border border-gray-200 rounded flex justify-center items-center h-8 mr-3 p-2"
        >
          <FontAwesomeIcon icon={faPhone} />
        </a>

        {!isOpen ? (
          <button
            onClick={() => {
              openNav()
            }}
            className="flex items-center px-2 py-2 border rounded text-gray-500 border-gray-200"
          >
            <svg className="fill-current h-4 w-8" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        ) : (
          <button
            onClick={() => {
              closeNav()
              setSubNavOpen(false)
            }}
            className="flex items-center py-0 px-2 border rounded text-gray-500 border-gray-200"
          >
            <svg className="icon icon-close fill-current h-8 w-8">
              <use xlinkHref="#icon-close"></use>
            </svg>
          </button>
        )}

        <svg style={{ display: 'none' }}>
          <symbol id="icon-close" viewBox="0 0 53.7 53.7">
            <title>Close icon</title>
            <path d="M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z" />
          </symbol>
        </svg>
      </div>
      <AnimateHeight className={classNames('lg:hidden', navItemContainerClasses)} height={height} duration={350}>
        <NavContents
          setSubNavOpen={setSubNavOpen}
          isSubNavOpen={isSubNavOpen}
          setOpen={setOpen}
          isOpen={isOpen}
          query={removeBuilderURLParam(query)}
          phoneNumber={phoneNumber}
          phoneNumberTxt={phoneNumberTxt}
        />
      </AnimateHeight>
      <div className={'hidden lg:flex lg:flex-row lg:flex-grow lg:items-center lg:w-auto'}>
        <NavContents
          setSubNavOpen={setSubNavOpen}
          isSubNavOpen={isSubNavOpen}
          setOpen={setOpen}
          isOpen={isOpen}
          query={removeBuilderURLParam(query)}
          phoneNumber={phoneNumber}
          phoneNumberTxt={phoneNumberTxt}
        />
      </div>
    </nav>
  )
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line react/display-name
export const NavItem = forwardRef<any, { text: string; onClick?: any; href?: any }>(({ text, onClick, href }, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <a
      href={href}
      onClick={onClick}
      ref={ref}
      className="block mt-4 text-base lg:inline-block font-semibold lg:mt-0 text-nugrey hover:text-black hover:no-underline mr-4"
    >
      {text}
    </a>
  )
})

type DropDownItem = {
  linkTitle: string
  link: string
  groupName: string
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line react/display-name
export const DropDownNavItem = forwardRef<
  any,
  {
    text: string
    onClick?: any
    isOpen: boolean
    subPages?: DropDownItem[]
    isSubNavOpen?: boolean
    setSubNavOpen?: any
  }
>(({ text, onClick, isOpen, subPages, isSubNavOpen }, ref) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <div className="wrapper inline-block relative">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          onClick()
        }}
        ref={ref}
        className={classNames({
          'trigger block mt-4 text-base lg:inline-block font-semibold lg:mt-0 text-nugrey hover:text-black hover:no-underline mr-4 hover:cursor-pointer':
            true,
          'is-active': isSubNavOpen,
        })}
      >
        {text}
      </a>
      <DropDownList
        onClick={onClick}
        isSubNavOpen={isSubNavOpen}
        isOpen={isOpen}
        subPages={subPages}
        renderSubPage={(page, index) => generatePage(page, index)}
      />
    </div>
  )
})

const generatePage = (page: DropDownItem, index) => {
  return (
    <a className="text-sm" key={'page-link-' + index} href={page.link}>
      {page.linkTitle}
    </a>
  )
}

const toggleHiddenNext = (target) => {
  const next = target.nextSibling
  if (next.classList.contains('is-hidden')) {
    next.classList.remove('is-hidden')
  } else {
    next.classList.add('is-hidden')
  }
}

function DropDownList({
  isOpen,
  subPages,
  renderSubPage,
  onClick,
  isSubNavOpen,
}: {
  isOpen: boolean
  subPages: DropDownItem[]
  renderSubPage: (item: DropDownItem, index: number) => ReactNode
  onClick?: any
  isSubNavOpen: boolean
}) {
  const byGroup = (subPages: DropDownItem[]) => groupBy(prop('groupName'), subPages)
  const linkCategories = byGroup(subPages)
  const linkCategoryKeys = Object.keys(linkCategories)

  return (
    // Portal takes the nav panel out of the regular document flow so it can be utilized at will in the UI
    // Portal maintains access to its react hierarchy and  state
    <ReactPortal>
      <nav
        className={classNames({
          'bg-white dropdown-menu': true,
          'is-active': isSubNavOpen,
        })}
      >
        <ul
          className={classNames({
            'service-group': true,
            'is-hidden': !isOpen,
          })}
        >
          <li key={'back-button'} className="text-base font-bold go-back has-children">
            <a
              onClick={(e) => {
                e.preventDefault()
                onClick()
              }}
              className="text-gray-500"
              href="#0"
            >
              BACK
            </a>
          </li>
          {linkCategoryKeys.map((linkCategory, i) => {
            return (
              <li key={i} className="text-lg font-bold group-title has-children">
                <a
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault()
                    toggleHiddenNext(e.target)
                  }}
                  className="text-nugray text-base uppercase title-link"
                >
                  {linkCategory} {/* / make this link out if there is only one under a group */}
                </a>
                <ul
                  className={classNames({
                    'nested-dropdown secondary-dropdown': true,
                    'is-hidden': isOpen,
                  })}
                >
                  <>
                    <li
                      onClick={(e) => {
                        const target = e.target as HTMLAnchorElement
                        target.parentElement.closest('ul').classList.add('is-hidden')
                      }}
                      className="go-back text-base relative"
                    >
                      <a
                        onClick={(e) => {
                          e.preventDefault()
                        }}
                        className="text-gray-500"
                        href="#0"
                      >
                        BACK
                      </a>
                    </li>
                    {linkCategories[linkCategory].map((page, index) => (
                      <li className="uppercase text-base" key={'link-' + index}>
                        {renderSubPage(page, index)}
                      </li>
                    ))}
                  </>
                </ul>
              </li>
            )
          })}
        </ul>
      </nav>
    </ReactPortal>
  )
}

function ReactPortal({ children, wrapperId = 'react-portal-wrapper' }) {
  if (typeof window !== 'undefined') {
    let element = document.getElementById(wrapperId)
    // if element is not found with wrapperId,
    // create and append to body
    if (!element) {
      element = createWrapperAndAppendToBody(wrapperId)
    }

    return createPortal(children, element)
  } else {
    return null
  }
}

function createWrapperAndAppendToBody(wrapperId) {
  if (typeof window !== 'undefined') {
    const wrapper = document.createElement('div')
    wrapper.id = wrapperId
    document.body.appendChild(wrapper)
    return wrapper
  }
}
